import { ro } from '@faker-js/faker';
import { GridColDef } from '@mui/x-data-grid-premium';
import { toCurrency } from '../../utils/numberFormatter';

export const googleReportsColumnDefs: GridColDef[] = [
  {
    field: 'matchtype',
    headerName: 'Match',
    headerClassName: 'bg-green',
    minWidth: 100,
    valueGetter: (_, row) => row.matchtype,
    flex: 1,
  },
  {
    field: 'Clicks',
    headerName: 'Clicks',
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 100,
    flex: 1,
  },
  {
    field: `CPC`,
    headerName: `CPC`,
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 100,
    flex: 1,
    valueGetter: (_, row) => row.CPC,
    renderCell: (params) =>
      params.rowNode.depth !== 2 ? toCurrency(`USD`, params.row.CPC, 2) : '',
  },
  {
    field: 'Impressions',
    headerName: 'Impressions',
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 100,
    flex: 1,
  },
  {
    field: `CPM`,
    headerName: `CPM`,
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 100,
    flex: 1,
    valueGetter: (_, row) => row.CPL,
    renderCell: (params) =>
      params.rowNode.depth !== 2 ? toCurrency(`USD`, params.row.CPM, 2) : '',
  },

  {
    field: 'Leads',
    headerName: 'Leads',
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 100,
    flex: 1,
  },
  {
    field: `CPL`,
    headerName: `CPL`,
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 100,
    flex: 1,
    valueGetter: (_, row) => row.CPL,
    renderCell: (params) =>
      params.rowNode.depth !== 2 ? toCurrency(`USD`, params.row.CPL, 2) : '',
  },
  {
    field: 'Costs',
    headerName: 'Cost',
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 100,
    flex: 1,
    valueGetter: (_, row) => row.Costs,
    renderCell: (params) =>
      params.rowNode.depth !== 2 ? toCurrency(`USD`, params.row.Costs, 2) : '',
  },
];
